<template>
  <div class="states-add animatedBox">
    <div class="container fluid">
      <div class="card mb-2 p-3">
        <fd-form @submit.prevent="submitForm">
          <h4 class="mainTitle">Add New Building</h4>
          <div class="row mt-4">
            <fd-input
              type="text"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              :label="`Building Name`"
              v-model="building.name"
              :validators="[validator.required]"
            />
            <fd-input
              type="text"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              :label="`Official Name`"
              v-model="building.officialName"
              :validators="[validator.required]"
            />
          </div>
          <div class="row">
            <addresses
              :country.sync="building.country"
              :state.sync="building.state"
              :city.sync="building.city"
              :area.sync="building.area"
              class="row col-12"
              @change="
                (data) => {
                  building.countryId = data.country.id;
                  building.stateId = data.state.id;
                  building.cityId = data.city.id;
                  building.areaId = data.area.id;
                }
              "
            ></addresses>
            <fd-select
              :label="'Parent Building'"
              class="col-12 sm-col-6 px-1 mb-2"
              v-model="building.parentBuildingId"
              :options="parentBuildingOptions"
              :selectText="
                $isStringEmpty(building.areaId)
                  ? 'Please select an area first'
                  : 'Choose a parent building (Optional)'
              "
              :disabled="$isStringEmpty(building.areaId)"
            >
            </fd-select>
            <fd-input
              v-model="building.street"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Street"
              type="text"
            >
            </fd-input>
          </div>

          <div class="mt-4 text-right">
            <button type="submit" class="btn main">Create</button>
          </div>
        </fd-form>
      </div>
    </div>
  </div>
</template>

<script>
import Addresses from "@/components/GlobalComponents/Address";
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";
import { BuildingModel } from "@/models";

export default {
  name: "states-add",
  components: {
    Addresses
  },
  data() {
    return {
      countryOptions: [],
      stateOptions: [],
      cityOptions: [],
      areaOptions: [],
      parentBuildingOptions: [],

      building: {
        name: "",
        officialName: "",

        country: "",
        state: "",
        city: "",
        area: "",

        countryId: "",
        stateId: "",
        cityId: "",
        areaId: "",
        street: "",
        parentBuildingId: ""
      },
      validator: {
        required: required
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      //
    },
    // ============================== API Related ==============================
    async getParentBuildings() {
      try {
        let data = await this.$store.dispatch(
          "manageBuildings/getAllBuildings",
          {
            limit: 100,
            "country:id": this.building.countryId,
            "state:id": this.building.stateId,
            "city:id": this.building.cityId,
            "area:id": this.building.areaId
          }
        );
        this.parentBuildingOptions = this._.cloneDeep(data.data);
      } catch (error) {
        throw error;
      }
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        await this.$store.dispatch(
          "manageBuildings/createBuilding",
          BuildingModel.postBuildingPayload(this.building)
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Building is created successfully."
        });
        this.$router.push({ name: "ManageBuildings" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.states-add {
  form {
    @extend %formDesign;
  }
}
</style>